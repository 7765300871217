.day-button-layout.ant-btn:hover,
.day-button-layout.ant-btn:focus,
.day-button-layout.ant-btn:active {
  color: rgba(0, 0, 0, 0.65);
  border-color: #d9d9d9;
  padding-left: 5px;
  padding-right: 5px;
}

.day-button-layout.ant-btn {
  padding-left: 5px;
  padding-right: 5px;
}

.anticon-question-circle {
  display: none;
}

.order-table .ant-pagination.ant-table-pagination {
  float: right !important;
}

.order-table .ant-pagination-total-text {
  display: inline;
  position: absolute;
  left: 0;
}

.order-table .ant-table-tbody > tr > td {
  border-left: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
}

.order-table .ant-table-thead {
  height: 60px;
}

.is-normal-row .ant-form-item {
  width: 75%;
}

.order-table .ant-table-thead > tr > th {
  border-left: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
  border-top: 0.5px solid #000;
}

.floating-actions {
  cursor: pointer;
}

.floating-button:focus,
.floating-button:active,
.floating-button {
  padding-left: 5px;
  padding-right: 5px;
  background-color: #2f83c4;
  border-color: #2f83c4;
  width: 70px;
  height: 40px;
}

.floating-button article {
  font-size: 12px;
  color: #ffffff;
}

.floating-button:hover {
  padding-left: 5px;
  padding-right: 5px;
  color: #ffffff;
  background-color: #2f83c4;
  width: 70px;
  height: 40px;
  transform: scale(1.1, 1.1);
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  border: 1px dashed #fff !important;
}

.floating-button i,
.floating-button i:hover {
  font-size: 12px;
  color: #ffffff;
}

/* .floating-popover {
  top: 320px !important;
} */

.floating-popover .ant-popover-inner {
  background-color: transparent;
}

.floating-popover .ant-popover-inner-content {
  padding: 0;
}

.floating-popover .ant-popover-arrow {
  background-color: #2f83c4 !important;
  border-right-color: #2f83c4 !important;
  border-bottom-color: #2f83c4 !important;
}

.order-table .ant-table {
  line-height: max(1.5);
}

.order-table .ant-table-tbody > tr > td {
  font-size: 15px;
  padding: 2px;
  vertical-align: baseline;
}

.order-table .ant-row.ant-form-item {
  margin-bottom: 5px !important;
}

.order-table .ant-table-row-cell-break-word .ant-row.ant-form-item:last-child {
  margin-bottom: 0px !important;
}

.order-table .ant-form-item-children input {
  border: none;
}

.order-table .edit-btn-trigger {
  border: none;
  padding: 3px;
  max-width: 40px;
  background-color: #2296f3;
  color: #ffffff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 12px;
}

.order-table .date-input {
  width: 90px !important;
  display: inline-block;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.order-table .ant-table-selection-column {
  vertical-align: middle !important;
}

.vertical-align {
  vertical-align: middle !important;
}

.order-table .ant-table-row-cell-break-word {
  cursor: pointer;
}

.scale:hover {
  transform: scale(1.2, 1.2);
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}

.is-normal-row i {
  padding-top: 7px;
}

.is-time-row i {
  padding-bottom: 7px;
}

.order-modal .ant-modal-footer {
  text-align: center;
}

.basic-form .ant-col.ant-form-item-label,
.escort-form .ant-col.ant-form-item-label,
.transport-form .ant-col.ant-form-item-label {
  width: 100px !important;
  text-align: right !important;
  margin-right: 15px;
}

.basic-form .no-width .ant-col.ant-form-item-label,
.escort-form .no-width .ant-col.ant-form-item-label,
.transport-form .no-width .ant-col.ant-form-item-label {
  width: unset !important;
}

.basic-form .edit-btn-trigger,
.escort-form .edit-btn-trigger,
.transport-form .edit-btn-trigger {
  border: none;
  padding: 3px;
  margin-top: 4px;
  max-width: 40px;
  background-color: #2296f3;
  color: #ffffff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  /* font-size: 12px; */
}

.basic-form .no-radius-right,
.escort-form .no-radius-right,
.transport-form .no-radius-right,
.transport-form .no-radius-right input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.transport-tab .ant-tabs-top-bar {
  margin: 0;
}

.transport-tab .ant-tabs-tabpane.ant-tabs-tabpane-active {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top-width: 0px;
}

.transport-tab .ant-tabs-nav-wrap {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.transport-tab .ant-tabs-tabpane {
  min-height: 580px;
}

.transport-form .avatar-uploader.ant-upload-picture-card-wrapper {
  /* width: unset !important; */
  display: unset !important;
}

.transport-form .ant-upload-list-picture-card-container {
  width: 90px !important;
  height: 75px !important;
}

.transport-form .ant-upload-list-picture-card-container .ant-upload-list-item {
  padding: 5px !important;
  width: 90px !important;
  height: 75px !important;
}

.transport-form .ant-upload.ant-upload-select-picture-card {
  width: 90px;
  height: 75px;
}

.transport-form .ant-upload.ant-upload-select-picture-card article {
  font-size: 13px;
}

.transport-form .escort-btn-trigger {
  margin-top: 4px;
  background-color: #2296f3;
  color: #ffffff;
  font-size: 12px;
}

.escort-collapse.ant-collapse-content-box {
  border-bottom: 1px solid #d9d9d9;
}

.customModal.basic-form-modal {
  width: unset !important;
  display: flex;
  align-items: center;
  height: 60px;
  margin-bottom: 8px;
}

.customModal.basic-form-modal.w-300 {
  width: 300px !important;
}

.customModal.basic-form-modal .ant-col.ant-form-item-label {
  margin-right: 15px;
}

.customModal.basic-form-modal .ant-btn {
  border: none;
  padding: 3px;
  max-width: 40px;
  background-color: #2296f3;
  color: #ffffff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0px !important;
}

.customModal.basic-form-modal input {
  width: 165px !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.customModal.basic-form-modal .modal-autocomplete {
  width: 165px !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff !important;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.pink-label {
  color: #dda0dd;
}

.green-label {
  color: green;
}

.orange-label-text {
  color: #ffa500 !important;
}
/* EE82EE */

.red-label {
  color: #e84855;
}

.orange-label {
  font-weight: bold;
  color: #e65100;
}

.text-bold {
  font-weight: 700;
}

.text-red {
  color: red;
}

.text-black {
  color: rgba(0, 0, 0, 0.65);
}

.text-green {
  color: green;
}

.amount .ant-table-header-column {
  text-align: center !important;
  width: 100%;
}

.order-table .ant-checkbox-inner {
  width: 12px;
  height: 12px;
}

.floating-button.active-btn {
  padding-left: 5px !important;
  padding-right: 5px !important;
  color: #ffffff !important;
  background-color: #2f83c4 !important;
  width: 70px !important;
  height: 40px !important;
  transform: scale(1.1, 1.1) !important;
  -webkit-transition: 0.1s ease-in-out !important;
  -moz-transition: 0.1s ease-in-out !important;
  -o-transition: 0.1s ease-in-out !important;
  transition: 0.1s ease-in-out !important;
  border: 1px dashed #fff !important;
}

.basic-form-modal {
  height: 39px !important;
}

.orders-search .ant-table .ant-table-header th {
  text-align: center !important;
}

.vehicle-cell {
  min-width: 70px;
}

.modal-details .ant-upload {
  display: none !important;
}

.order-table .date-input.ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.ant-input-number-input {
  ime-mode: disabled;
}

.package-modal,
.customer-modal {
  width: 280px !important;
}
