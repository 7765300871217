.icon {
  margin-left: 20px;
  color: #001529;
  cursor: pointer;
  font-size: 20px;
  align-items: center;
  display: flex;
}

.mainheader {
  box-shadow: 4px 4px 40px 0 rgba(0,0,0,.05);
  background: #fff;
  padding: 0;
  display: flex;
  z-index: 9;
  justify-content: space-between;
  transition: width .2s;
}

.rightContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 20px;
  ul {
    border-bottom: 2px solid #fff;
  }
}

.breadcrumb {
  padding: 15px 15px 0;
}