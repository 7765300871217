.sidebar {
  transition: all .2s;
  position: relative;
  background: #001529;
  min-width: 0;
}
.logo {
  padding: 25px 0px 15px;
  margin: 0;
  position: relative;
  z-index: 4;
  display: block;
  text-align: center;
  h3 {
    margin: 0;
    color: #ffffff;
  }
}
.menuContainer {
  height: calc(100vh - 110px);
  // height: 100vh;
  overflow-x: hidden;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 24px 0;
}