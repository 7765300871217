.table {
  margin-top: 0;
  thead{
    tr {
      th {
        background-color: #40a9ff;
        color: #fff;
        vertical-align:center ;
        height: 40px;  
             // text-align: center !important;
      }
    }
  }
}