body,
html,
#root {
  height: 100%;
  background-color: #f8f8f8;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB",
    "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.main-panel {
  position: relative;
  z-index: 2;
  float: right;
  width: calc(100% - 256px);
  max-height: 100%;
  height: 100%;
}
.active {
  color: red;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.clearfix {
  overflow: auto;
}

.h100 {
  height: 100%;
}
.ant-modal-header {
  text-align: center;
}
.ant-modal-body .mapHeight {
  height: 450px !important;
}
.ant-layout-sider-collapsed .ant-layout-sider-children h3 {
  width: 10px;
  text-align: center;
  overflow: hidden;
  margin: 0 auto;
}
.ant-layout-sider-collapsed .ant-layout-sider-children h3.hid-sm {
  display: none !important;
}

.ant-layout-footer {
  padding: 0px 0px;
}

.ant-layout-content {
  padding: 0px 0px;
  margin: 20px 20px;
}

.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid transparent;
}

.ant-form-item {
  margin-bottom: 15px;
}

.ant-form-item-label {
  text-align: left;
}

.ant-tabs-tabpane {
  min-height: 600px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  /* text-indent: -40px; */
}

.ant-modal-confirm-body-wrapper {
  text-align: center;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin: 24px auto 0;
  float: none;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin: 0;
}

.login-enter {
  opacity: 0.01;
}

.login-enter.login-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.login-leave {
  opacity: 1;
}

.login-leave.login-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.login-appear {
  opacity: 0.01;
}

.login-appear.login-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

header span > .ant-alert.ant-alert-no-icon {
  margin-bottom: 15px;
}

.search-filter {
  background-color: #40a9ff;
  color: #fff;
  display: block;
  padding: 10px;
  margin-bottom: 15px;
}

.ant-layout .ant-input-number {
  width: auto;
}

.ant-table-pagination.ant-pagination {
  float: left;
  margin: 10px 0px;
}
.ant-pagination-total-text {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.listPor .ant-table-pagination.ant-pagination {
  float: left;
  margin-top: 0;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .anticon-paper-clip,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #21981a;
}

.ant-upload-list-item-error .anticon-close {
  opacity: 1;
  color: #21981a !important;
}

.custom-upload button {
  float: right;
}

.custom-upload > span {
  display: inline-block;
  width: 450px;
  margin-left: 90px;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  font-size: 32px;
  color: #40a9ff;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 0;
}

.ant-tooltip.ant-tooltip-placement-top {
  display: none;
}

.ant-modal-body .ant-table-pagination.ant-pagination {
  float: left;
  margin-top: 0;
}
.setLabel > div:first-child {
  width: 33.33333334%;
}
.estimate-cname > div:first-child {
  width: 123px;
}
.smallButton button {
  width: 47px;
  padding: 0;
  margin-right: 7px;
}
.clear {
  display: block;
  float: left;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 0;
}
.ordersModals .ant-form-item {
  margin-bottom: 10px;
}
.three_columnn .ant-form-item {
  width: 25%;
  float: left;
}
.three_columnn .ant-form-item:last-child {
  background: red;
}
.three_columnn .ant-form-item input {
  width: 140px !important;
}
.order_reg > .ant-row.ant-form-item {
  float: left;
  width: 390px;
  margin: 0;
  padding: 0;
}
.smallButton button:last-child {
  margin-right: 0 !important;
}

/* .order_reg .ant-form-item-control-wrapper .ant-form-item-control .ant-select-selection{
    width: 200px; 
} */
.custom_trans_stat > .ant-form-item-label {
  width: 195px;
}
.custom_trans > .ant-form-item-label {
  width: 195px;
}
.custom_trans > div > div:first-child {
  width: 195px;
}
ul.ant-pagination.ant-pagination-simple.right {
  clear: both;
  width: 100%;
  text-align: right;
  margin-bottom: 10px;
}

.modal-vehicle .ant-modal-footer {
  border-top: none;
  /* text-align: center; */
  padding: 10px 16px 24px;
}
.modal-vehicle .ant-modal-body {
  padding-bottom: 0;
}

/* .ant-table table {
    table-layout: fixed;
    width: 100%;
} */

.ant-table-thead > tr > th {
  padding: 8px;
  border-left: 1px solid #e8e8e8;
  background-color: #40a9ff;
  color: #fff;
  height: 40px !important;
  text-align: center;
}

.ant-table-tbody > tr > td {
  padding: 8px;
  border-left: 1px solid #e8e8e8;
  word-wrap: break-word;
  overflow-wrap: break-word;
  /* width: 100%;  */
}

.ant-table-placeholder {
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.dp-alignment input {
  margin-left: 8px;
}

.floatToRight {
  position: absolute;
  right: -200px;
  top: -15px;
}
.customModal input {
  width: 200px;
}
.ant-table-column-sort {
  background-color: #40a9ff;
  color: #fff;
  text-align: center;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow: auto;
  opacity: 0.9999;
}
.ant-table-thead-align-center > div {
  text-align: center;
}

.ant-form-item-control > span.ant-form-item-children {
  display: block;
  line-height: 0;
}
.ant-tabs-content .ant-form-item-control > span.ant-form-item-children {
  display: block;
  line-height: normal;
}

p.ant-empty-description {
  display: none;
}

.ant-input-number-input-wrap,
.ant-input-number {
  width: 100%;
}
.customModal {
  height: auto;
  zoom: 1;
  display: flex;
  margin-bottom: 0;
  padding-bottom: 0 !important;
  width: 100%;
}
.vehicleModal .customModal {
  height: auto;
  zoom: 1;
  display: flex;
  margin-bottom: 0;
  padding-bottom: 0 !important;
  width: 440px;
  float: left;
}
.ordersModals .customInput {
  float: left;
  margin-right: 15px;
}
.ordersModals .customModal {
  width: 300px;
  float: left;
}
.ordersModals .customModal .ant-form-item-children .ant-btn {
  margin-right: 15px !important;
  margin-left: 15px !important;
}
.transportconfigclass {
  position: absolute;
  top: 0;
  left: 50%;
}
.transportconfigclass > div {
  width: 100px;
}
.transportconfigclass label {
  position: absolute;
  top: 15px;
  right: -90px;
}

.ant-alert-error {
  /* border: 1px solid #ffa39e !important; */
  background-color: #fff1f0 !important;
  padding: 0px;
}
/* .ant-card.ant-alert.ant-alert-error.ant-alert-with-description.ant-alert-no-icon.ant-card-bordered.ant-card-wider-padding {
    border: 1px solid #ffa39e !important;
} */
/* .ant-alert-error.ant-card-wider-padding .ant-card-body {
    padding: 0;
} */

.ant-modal-confirm-btns,
.modal-vehicle .ant-modal-footer > div {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: center;
}

.ant-modal-confirm-btns button.ant-btn,
.modal-vehicle .ant-modal-footer > div button.ant-btn {
  margin-left: 10px;
  order: 2;
}
.ant-modal-confirm-btns button.ant-btn.ant-btn-primary,
.modal-vehicle .ant-modal-footer > div button.ant-btn.ant-btn-primary {
  order: 1;
}
.crewperformance > div {
  width: 300px;
  float: left;
}
.crewperformance > div.range-symbol {
  min-width: 0;
  width: 12px;
  font-size: 22px;
  line-height: 34px;
  margin-right: 10px;
}
.crewperformance > div > div {
  line-height: 3 !important;
}
.crewperformance > div:nth-child(3) > div {
  width: 50% !important;
}
.crewperformance > div > div:nth-child(2) {
  width: 50% !important;
}
.dispatchconfig > div {
  min-width: 300px;
  float: left;
}
.dispatchconfig > div.range-symbol {
  min-width: 0;
  width: 12px;
  font-size: 22px;
  line-height: 34px;
  margin-right: 10px;
}
.dispatchconfig > div > div {
  line-height: 2.5 !important;
}
.dispatchconfig > div > div:nth-child(2) {
  width: 50% !important;
}
.dispatchconfig > div:nth-child(3) > div {
  width: 50% !important;
}
.estimate-cname {
  margin-top: 12px;
}
.estimate-cname > div:nth-child(2) {
  width: 13.7%;
}
.order_reg > .customModal {
  padding-left: 15px !important;
}
.vehicleStatus > div:first-child {
  width: 120px !important;
}
.vehicleStatus > div:nth-child(2) {
  width: 230px !important;
}
.ant-modal-confirm-title {
  margin-bottom: 25px;
}
.ant-table-row.lblue-row {
  background: #d1f3ff;
}
.ant-table-row.blue-row {
  background: #bbdaf1;
}
.ant-table-row.highlighted {
  background: #c6e0b4 !important;
}

.ant-table-row.pink-row {
  background: #DCDCDC;
}

.ant-table-row.dark-blue {
  background: #C0C5E3;
}

.ant-table-row.yellow-row {
  background: #fcf4a3;
}
.ant-table-row.dblue-row-truck {
  background: #a0dbf0;
}

.ant-table-row.dblue-row {
  background: #bbdaf1;
}

form .has-feedback > .ant-select .ant-select-selection-selected-value, form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 0px !important;
}
.border-graph {
  overflow: hidden;
  border: 1px solid rgb(217, 217, 217);
  padding: 20px;
}
.mxl-10 {
  margin-left: 15px;
}
.four_column > .ant-form-item {
  position: relative;
  float: left;
  width: 25%;
}

.four_column > .ant-form-item label {
  position: absolute;
  right: -20px;
  top: 15px;
}
.four_column > .ant-form-item:nth-child(2) label {
  position: absolute;
  right: -36px;
  top: 15px;
}
@media only screen and (min-width: 1760px) {
  .four_column > .ant-form-item label {
    position: absolute;
    right: -24px;
    top: 15px;
  }
  .four_column > .ant-form-item:nth-child(2) label {
    position: absolute;
    right: -40px;
    top: 15px;
  }
}

.lbltax {
  float: right;
  margin-top: 13px !important;
  margin-right: -20px !important;
}

@media only screen and (min-width: 1760px) {
  .mxl-10 {
    /* width: 1060px; */
  }
}
.ant-calendar-month-panel-year-select-content:after,
.ant-calendar-year-panel-year:after,
.ant-calendar-year-panel-decade-select-content:after,
.ant-calendar-decade-panel-century:after {
  content: "年";
}
.customDatepickStyle {
  width: 265px;
  float: left;
}
.customDatepickStyle .ant-form-item-control-wrapper.ant-col-xs-24.ant-col-sm-24.ant-col-md-24.ant-col-md-offset-0 {
  width: 140px;
}
.customDatepickStyle .ant-form-item-label.ant-col-xs-24.ant-col-sm-8.ant-col-md-8.ant-col-lg-90 {
  width: 100px;
}
.customDatepickStyle2 {
  /* width: 300px; */
  margin-bottom: 0px;
  float: left;
}
.customDatepickStyle2 .ant-form-item-label.ant-col-xs-24.ant-col-sm-8.ant-col-md-8.ant-col-lg-3 {
  width: 123px;
}
.customDatepickStyle2 .ant-form-item-control-wrapper.ant-col-xs-24.ant-col-sm-24.ant-col-md-24.ant-col-md-offset-0 {
  width: 140px;
  margin-left: 0;
}
.date-separator {
  position: relative;
  float: left;
  margin: 0px 5px;
  top: 5px;
}
.ant-row.ant-form-item.customDatepickStyle3 {
  width: 150px;
  float: left;
  margin-bottom: 10px;
  display: inline;
}
.clearme {
  clear: both;
}
.ant-form-item-label label:after {
  display: none;
}
.modelStyle .ant-btn-primary {
  color: #fff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.modelStyle .ant-btn-primary:hover {
  background-color: none;
  text-decoration: none;
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff;
}
#subtotal,
#consumptionTax,
#totalAmount {
  text-align: right;
  padding-right: 20px;
}
.planeBtn {
  margin-right: 5px;
  color: #1890ff;
  background-color: transparent;
  text-decoration: none;
  border: none;
  padding: 0;
  box-shadow: none;
}
.planeBtn:hover {
  background: transparent;
}
.planeBtn > span {
  padding: 0 2px;
}
.estimateModal .ant-modal-body {
  padding-bottom: 0;
  padding-top: 0;
  padding-left: 15px;
  padding-right: 15px;
}
.estimateModal .ant-modal-header {
  padding: 15px 24px 0;
  border-bottom: none;
}
.ant-form label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

@media only screen and (min-width: 768px) {
  .actions-container {
    position: absolute;
    right: 0;
  }
  .custom-table-wrapper:not(.hasPagination) {
    padding-top: 91px;
  }
}
/*** MISC ***/
.input-text-right input {
  text-align: right;
  padding-right: 25px;
}
.input-text-left input {
  text-align: left;
}
.customModal.cm input {
  width: 265px;
}

.customModal.cm .modal-autocomplete {
  width: 265px !important;
}
.optionfirst {
  height: 32px;
}
.selectDatepick {
  float: left;
  width: 175px;
}
.selectTimepick {
  float: left;
  width: 140px;
}
/* .ant-table .ant-table-fixed {
    width: 1500px;
} */
/* .gm-style .gm-style-mtc div:nth-child(2) {
    display: none;
} */

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  line-height: 2;
}

.radio-button-layout {
  left: 21%;
  bottom: 20px;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #f5222d;
  font-size: 10px;
}

.table_table__3uuYr thead tr th {
  background-color: #40a9ff;
  color: #fff;
  vertical-align: center;
  height: 40px !important;
}

.table_table__1o-XR thead tr th {
  background-color: #40a9ff;
  color: #fff;
  vertical-align: center;
  height: 40px !important;
}
.table_table__2g_Vo thead tr th {
  background-color: #40a9ff;
  color: #fff;
  vertical-align: center;
  height: 40px !important;
}

.ant-table-header ant-table-hide-scrollbar {
  margin-bottom: -17px;
  padding-bottom: 0px;
  overflow: scroll;
}

.great-wall > td {
  border-bottom: 3px solid #40a9ff !important;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #c6e0b4;
}

.order-table.no-highlight
  > .ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.order-table.no-highlight
  > .ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.order-table.no-highlight > .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.order-table.no-highlight > .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: none !important;
}

.userConfigAuthority .ant-checkbox-group-item {
  display: block;
  line-height: 2;
}

#order_action_search_form .ant-form-item-label {
  text-align: right !important;
  padding-right: 10px;
}

div#order_action_search_form_table .ant-table-pagination.ant-pagination {
  float: right !important;
  margin-top: 10px !important;
}

@media print {
  @page {
    size: landscape;
  }
}

.ant-select-dropdown {
  background-color: #fff !important;
}