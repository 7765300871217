
.logincontainer {
  border: 1px solid #2296f3;
  padding-right: 0;
  padding-left: 0;
  flex-basis: 25%;
  max-width: 25%;
}
.group {
  margin: 0;
  padding: 25px 0;
  .button {
    padding: 10px 30px;
    box-shadow: none;
    background-color: #2296f3;
  }
  label {
    text-align: left;
  }
}

.login {
  box-shadow: 0 0 100px rgba(0,0,0,.08);
  min-width: 320px;
  width: 320px;
  padding: 36px;
  margin-top: -50px;

  header{
    h3{
      font-size: 20px;
      margin: 15px 0px;
      font-family: 'Mukta Mahee', sans-serif;
      text-align: center;
    }
  }
  button {
    width: 100%;
  }
}