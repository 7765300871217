.alert {
  margin-bottom: 20px;
}
.datepicker {
  max-width: 140px;
  margin: 3px 10px 10px 0px;
}

.button {
  margin: 0px 10px 10px 10px;
  button {
    border: none;
    box-shadow: none;
    border-radius: 0;
    width: 120px;
  }
}
.buttonFlat {
  margin: 3px 10px 10px 10px;
  border: none;
  box-shadow: none;
  border-radius: 0;
  width: 220px;
}
.buttonFlat:only-child {
  margin-left: auto;
  margin-right: 0;
}
.buttonContainer {
  text-align: right;
  position: relative;
  right: 0;
  margin-top: 14px;
  margin-bottom: 5px;
  z-index: 100;

  button:only-child {
    margin-left: auto;
    margin-right: 0;
  }

  button {
    width: 120px;
  }
}
.hidden{
  display: none;
}
.leftAlign {
  td{
    text-align: left
  }
  element{
    text-align: left;
}
}

.labelAlign {
  line-height:25
}