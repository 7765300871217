/* ORDERS MOBILE CSS */
.orders-mb-container {
  min-height: 100vh;
  border: 1px solid #4a4a4a;
  border-top-width: 0px;
}

.orders-mb-header {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: fixed;
  width: calc(100% - 2px);
  z-index: 2;
  border-top: 1px solid #4a4a4a;
  border-bottom: 1px solid #4a4a4a;
}

.orders-mb-logout {
  position: fixed;
  padding: 10px;
  margin-right: 10px;
  right: 0;
}

.orders-mb-header,
.orders-mb-container ul,
.orders-mb-container li {
  background-color: #f8f8f8;
}

.orders-mb-search {
  margin-top: 50px;
  height: 70px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.orders-mb-table {
  border-top: 1px solid #4a4a4a;
  font-size: 12px;
}

.orders-mb-search input,
.orders-mb-container article,
.orders-mb-container a,
.orders-mb-container span {
  font-size: 12px;
}

.ant-table-thead > tr:first-child > th:first-child,
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.ant-pagination.ant-table-pagination {
  margin-right: 10px;
}
